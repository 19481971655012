import * as React from 'react';
import styled from '../theme';
import Icon from './Icon';
import { Flex } from './Flex';
import UserDropdown from './UserDropdown';
import SubHeader from '../components/SubHeader';

interface HeaderContainerProps {
  height: string;
}

const HeaderContainer = styled.div<HeaderContainerProps>`
  height: ${(props: HeaderContainerProps) => props.height};
  background-color: ${({ theme }) => theme.colors.highlightOne};
`;

const RTSIcon = styled(Icon)`
  margin-left: 64px;
  cursor: pointer;
`;

const Header: React.FunctionComponent = () => {
  return (
    <HeaderContainer height="104px">
      <Flex height="64px" alignItems="center" justifyContent="space-between">
        <RTSIcon
          name="RtsProWhite"
          height={24}
          alt="RTS Pro Icon"
          onClick={() => (window.location.href = 'https://rtspro.com/#/home')}
        ></RTSIcon>
        <UserDropdown />
      </Flex>
      <SubHeader />
    </HeaderContainer>
  );
};

export default Header;
