import {
  format,
  isToday,
  isTomorrow,
  isSameDay,
  differenceInWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isAfter,
  addMinutes,
} from 'date-fns';

export const monthDayTimeFormat = (value: string) => {
  const date = new Date(value);
  const dateWithoutTimezoneOffset = getDateWithoutTimezoneOffset(value);

  if (isToday(dateWithoutTimezoneOffset)) {
    return `Today ${formatWithoutTimezoneOffset(date, 'HH:mm')}`;
  } else if (isTomorrow(dateWithoutTimezoneOffset)) {
    return `Tomorrow ${formatWithoutTimezoneOffset(date, 'HH:mm')}`;
  }
  return formatWithoutTimezoneOffset(new Date(value), 'MM/dd HH:mm');
};

export const timeFormat = (value: string, firstDate: string) => {
  return isSameDay(new Date(value), new Date(firstDate))
    ? formatWithoutTimezoneOffset(new Date(value), 'HH:mm')
    : monthDayTimeFormat(value);
};

export const getDateWithoutTimezoneOffset = (value: string) => {
  const date = new Date(value);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const formatWithoutTimezoneOffset = (
  date: Date,
  stringFormat: string
) => {
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const dateWithoutOffset = new Date(date.getTime() + userTimezoneOffset);
  return format(dateWithoutOffset, stringFormat);
};

export const getTimeSinceDate = (dateString: string) => {
  const now = new Date();
  const date = new Date(dateString);

  const weeks = differenceInWeeks(now, date);
  if (weeks > 0) {
    return `${weeks}w`;
  }

  const days = differenceInDays(now, date);
  if (days > 0) {
    return `${days}d`;
  }

  const hours = differenceInHours(now, date);
  if (hours > 0) {
    return `${hours}h`;
  }

  const minutes = differenceInMinutes(now, date);
  if (minutes > 0) {
    return `${minutes}m`;
  }

  const seconds = differenceInSeconds(now, date);
  if (seconds > 0) {
    return `${seconds}s`;
  }

  return '';
};

export const isFifteenMinutesPastTime = (
  expirationTimer: number,
  value?: string
) => {
  if (!value) {
    return false;
  }
  const date = new Date(value);
  const now = new Date();

  return isAfter(now, addMinutes(date, expirationTimer));
};

export const calculateTimeLeft = (createdAt: string, minutes: number) => {
  const createdAtDate = new Date(createdAt);
  const minutesAfter = addMinutes(createdAtDate, minutes);
  const difference = +minutesAfter - +new Date();

  if (difference > 0) {
    const minutes = Math.floor((difference % 3.6e6) / 6e4);
    const seconds = Math.floor((difference % 6e4) / 1000);

    return {
      minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
      seconds: seconds < 10 ? `0${seconds}` : `${seconds}`,
    };
  }
  return { minutes: '00', seconds: '00' };
};

export const formatMonthDate = (value: string) => {
  let date = new Date(value);
  return formatWithoutTimezoneOffset(new Date(value), 'MM/dd ');
};

export const formatDate = (date: Date) =>
  formatWithooutTimezoneOffset(date, 'MM/dd/yyyy');

// we dont want to adjust timezone so reset the offset and format
const formatWithooutTimezoneOffset = (date: Date, stringFormat: string) => {
  if (!isValidDate(date)) return '';

  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const dateWithoutOffset = new Date(date.getTime() + userTimezoneOffset);
  return format(dateWithoutOffset, stringFormat);
};

const isValidDate = (date: Date) => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    // it is a date
    if (isNaN(date.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return false;
    } else {
      // date is valid
      return true;
    }
  } else {
    // not a date
    return false;
  }
};
