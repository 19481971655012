import pMemoize from 'p-memoize';

export const getPlaceSuggestions = (
  place: string
): Promise<google.maps.places.AutocompletePrediction[]> => {
  const SOUTHWEST_BOUND = new google.maps.LatLng({
    lat: 5.49955,
    lng: -167.276413,
  });
  const NORTHEAST_BOUND = new google.maps.LatLng({
    lat: 83.162102,
    lng: -52.23304,
  });
  const MAP_BOUNDS = new google.maps.LatLngBounds(
    SOUTHWEST_BOUND,
    NORTHEAST_BOUND
  );
  return new Promise((resolve, reject) => {
    new google.maps.places.AutocompleteService().getPlacePredictions(
      { input: place, types: ['(regions)'], bounds: MAP_BOUNDS },
      (data, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          return resolve(data);
        }
        return reject(status);
      }
    );
  });
};

export const getLatLong = (
  placeId: string
): Promise<google.maps.LatLng | null> => {
  const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId: placeId }, (results, status) => {
      if (status === 'OK') {
        let location = results[0].geometry.location;
        return resolve(location);
      } else reject(status);
    });
  });
};

export const getLocation = pMemoize(
  (address: string): Promise<[number, number]> => {
    return new Promise((resolve, reject) => {
      new google.maps.Geocoder().geocode(
        { address: address },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              const [
                {
                  geometry: {
                    location: { lat, lng },
                  },
                },
              ] = results;

              resolve([lat(), lng()]);
            }
            reject('Geocode was not successful');
          } else {
            reject(
              `geocode was not successful for the following reason: ${status}`
            );
          }
        }
      );
    });
  },
  { maxAge: 604800000 }
);
