import { UserMetadata } from 'utilities/authUtils';
import http from '../../utilities/httpUtils';
import { Lanes, Lane, NotificationPreferenceData } from './myLanesTypes';

export const getLanes = http.get<Lanes, undefined>('lanes');

export const createOrUpdateUserLanes = (lanes: Lane[]) => {
  return http.put<Lanes, { lanes: Lane[] }>('lanes')({ lanes });
};

export const updateLanes = async (editedLanes: Lane[]) => {
  const wholeLanesObj = await http.put<Lanes, { lanes: Lane[] }>('lanes')({
    lanes: editedLanes,
  });

  return wholeLanesObj;
};

export const updateNotificationsPreference = http.put<
  Lanes,
  { notificationPreference: NotificationPreferenceData }
>('lanes/updatePreference');

export const deleteUserLanesById = http._delete<Lanes, { ids: string[] }>(
  'lanes'
);

export const updateUserMetada = http.put<unknown, UserMetadata>(
  'update-user-metadata'
);
