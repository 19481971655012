import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from '../utilities/authUtils';
import styled from '../theme';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import process from 'process';

const DropdownContent = styled.ul`
  font-size: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgroundOne};
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colors.dropshadow};
  width: 190px;
  display: block;
  list-style-type: none;
  right: 48px;
  width: 200px;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1400;
  padding: 0;
  padding-top: 0.5rem;
`;

const UserDropdownContainer = styled.div`
  display: block;
  cursor: pointer;
  margin-right: 75px;
`;

const UserNameContainer = styled.div`
  color: ${({ theme }) => theme.colors.backgroundOne};
  font-size: ${({ theme }) => theme.font.size.mediumLarge};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  flex-direction: row;
  position: relative;
  vertical-align: middle;
  text-align: right;
`;

const NameText = styled.div`
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.5rem;
`;

const ListItem = styled.li`
  display: block;
  text-decoration: none;
  margin: 0;
  font-size: ${({ theme }) => theme.font.size.small};
`;

const ListLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.textTwo};
  text-decoration: none;
  font-weight: normal;
  text-align: left;
  padding: 5px;
  &:hover {
    color: ${({ theme }) => theme.colors.backgroundOne} !important;
    background-color: ${({ theme }) => theme.colors.highlightOne};
  }
`;

const UserDropdown: React.FunctionComponent = () => {
  const user = getCurrentUser();
  const name = user ? user.firstname + ' ' + user.lastname : '';
  const [isShowDropdown, setShowDropdown] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: Event) => {
    if (wrapperRef && wrapperRef.current) {
      if (!wrapperRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }
  };

  return (
    <UserDropdownContainer ref={wrapperRef}>
      <UserNameContainer onClick={() => setShowDropdown(true)}>
        <NameText>{name} &#9662;</NameText>
      </UserNameContainer>
      {isShowDropdown && (
        <DropdownContent>
          <ListItem>
            <ListLink href="https://rtspro.com/#/user/accounts">
              {t('settings')}
            </ListLink>
          </ListItem>
          <ListItem>
            <ListLink
              onClick={() =>
                LocalAuth.redirectToLogout(
                  process.env.REACT_APP_LOADBOARD_URL || ''
                )
              }
            >
              Log out
            </ListLink>
          </ListItem>
        </DropdownContent>
      )}
    </UserDropdownContainer>
  );
};

export default React.memo(UserDropdown);
