export const capitalizeFirstLetterOfEachWord = (string: string) => {
  if (string.includes(' ')) {
    let split = string.toLowerCase().split(' ');
    for (var i = 0; i < split.length; i++) {
      split[i] =
        split[i].slice(0, 1).toUpperCase() +
        split[i].slice(1, split[i].length).toLowerCase();
    }
    return split.join(' ');
  }
  return (
    string.slice(0, 1).toUpperCase() +
    string.slice(1, string.length).toLowerCase()
  );
};

export const addSpaceAfterCommas = (string?: string) =>
  string ? string.replace(/,/g, ', ') : string;
