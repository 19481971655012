import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from '../theme';

const FooterContainer = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.textFour};
  font-size: ${({ theme }) => theme.font.size.small};
  padding: 0px 64px 12px 64px;
  min-width: 750px;
`;

const EmailLink = styled.a`
  color: ${({ theme }) => theme.colors.highlightOne};
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.textFour};
`;

export const Seperator = styled.span`
  padding-left: 10px;
  padding-right: 10px;
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      {`© Ryan Transportation Service, Inc. ${t('all_rights_reserved')}`}
      <Seperator>|</Seperator>
      <Link href="https://www.ryantrans.com/page/terms-use">
        {t('footer_terms')}
      </Link>
      <Seperator>|</Seperator>
      <Link href="https://www.ryantrans.com/page/privacy-policy">
        {t('footer_privacy')}
      </Link>
      <Seperator>|</Seperator>
      <Link href="https://www.ryantrans.com/page/customer-portal-terms-of-use">
        {t('footer_conditions')}
      </Link>
      <Seperator>|</Seperator>
      <EmailLink href="mailto:quoting@ryantrans.com">
        quoting@ryantrans.com
      </EmailLink>
    </FooterContainer>
  );
};

export default Footer;
