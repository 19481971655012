import { useQueryClient, useMutation, useQuery } from 'react-query';
import { getLoads } from '../findLoads/findLoadsHttp';
import {
  DeadheadSearch,
  DeadheadSearchQuery,
  FindLoadsTableData,
} from '../findLoads/findLoadsTypesV2';
import {
  Lanes,
  Lane,
  MyLanesSearchData,
  NotificationPreferenceData,
} from './myLanesTypesV2';
import { getDetails } from '../findLoads/findLoadsUtils';
import {
  getLanes,
  updateLanes,
  updateNotificationsPreference,
  createOrUpdateUserLanes,
  deleteUserLanesById,
} from './myLanesHttpV2';
import _ from 'lodash';
import { formatDate } from '../../utilities/dateUtils';
import { logLane } from './lanesAnalyticsV2';
import { useMyLanesQueryParams } from '../../hooks/useQueryParams';

export const getStatesString = (states: string[] | undefined): string => {
  if (states) return states.join(',');
  else return '';
};

export const getDeadheadQuery = (lane: Lane): DeadheadSearchQuery => {
  let query: DeadheadSearchQuery = {
    equipmentType: lane.trailerTypes.join(','),
  };
  //pickup params
  if (lane.pickupType === 'City' && lane.pickupCity) {
    query.latitude = lane.pickupCity.lat;
    query.longitude = lane.pickupCity.long;
    query.pickupDeadheadMiles = 50;
  } else if (
    (lane.pickupType === 'States' || !lane.pickupType) &&
    lane.pickupStates
  ) {
    query.pickupStates = getStatesString(lane.pickupStates);
  }
  //delivery params
  if (lane.deliveryType === 'City' && lane.deliveryCity) {
    query.deliveryLatitude = lane.deliveryCity.lat;
    query.deliveryLongitude = lane.deliveryCity.long;
    query.deliveryDeadheadMiles = 50;
  } else if (
    (lane.deliveryType === 'States' || !lane.deliveryType) &&
    lane.deliveryStates
  ) {
    query.deliveryStates = getStatesString(lane.deliveryStates);
  }

  query.orderMode = 'T,P';

  return query;
};

export const getCSVString = (lane: Lane) => {
  let pickupString = '';
  let deliveryString = '';

  if (!lane.pickupType || lane.pickupType === 'States')
    pickupString = getStatesString(lane.pickupStates);
  else if (lane.pickupType === 'City' && lane.pickupCity)
    pickupString = `${lane.pickupCity.lat}${lane.pickupCity.long}`;
  else pickupString = 'Anywhere';

  if (!lane.deliveryType || lane.deliveryType === 'States')
    deliveryString = getStatesString(lane.deliveryStates);
  else if (lane.deliveryType === 'City' && lane.deliveryCity)
    deliveryString = `${lane.deliveryCity.lat}${lane.deliveryCity.long}`;
  else deliveryString = 'Anywhere';

  return `${pickupString}-${deliveryString}-${lane.trailerTypes.join(',')}`;
};

export const getSearchResultsForLanes = async (lanes: Lane[]) => {
  const result = await Promise.all(
    lanes.map((x) => getLoads(getDeadheadQuery(x)))
  );
  return result.reduce(
    (results: { [key: string]: DeadheadSearch[] }, response, index) => {
      const key = getCSVString(lanes[index]);
      if (results[key] === undefined) {
        results[key] = response;
      }
      return results;
    },
    {}
  );
};

export const getLocationLane = (data: Lane) => {
  const location = { pickup: '', delivery: '' };
  if (data.pickupType === 'Anywhere') {
    location.pickup = 'Anywhere';
  }
  if (data.pickupType === 'City') {
    location.pickup = data.pickupCity?.cityName || '';
  }

  if (data.pickupType === 'States') {
    location.pickup = data.pickupStates?.join(',') || '';
  }

  if (data.deliveryType === 'Anywhere') {
    location.delivery = 'Anywhere';
  }
  if (data.deliveryType === 'City') {
    location.delivery = data.deliveryCity?.cityName || '';
  }

  if (data.deliveryType === 'States') {
    location.delivery = data.pickupStates?.join(',') || '';
  }
  return location;
};

export const getLocation = (data: MyLanesSearchData) => {
  const location = { pickup: '', delivery: '' };
  if (data.pickupType === 'Anywhere') {
    location.pickup = 'Anywhere';
  }
  if (data.pickupType === 'City') {
    location.pickup = data.pickupCity?.cityName || '';
  }

  if (data.pickupType === 'States') {
    location.pickup = data.pickupStates || '';
  }

  if (data.deliveryType === 'Anywhere') {
    location.delivery = 'Anywhere';
  }
  if (data.deliveryType === 'City') {
    location.delivery = data.pickupCity?.cityName || '';
  }

  if (data.deliveryType === 'States') {
    location.delivery = data.pickupStates || '';
  }
  return location;
};

const getLaneSearchResult = (
  laneSearchResults: { [key: string]: DeadheadSearch[] },
  lane: Lane
) => {
  const pickupStatesCSV = getStatesString(lane.pickupStates);
  const deliveryStatesCSV = getStatesString(lane.deliveryStates);
  const trailerTypeCSV = lane.trailerTypes.join(',');
  const key = getCSVString(lane);

  const foundLaneSearchData = laneSearchResults[key];

  return {
    pickupStates: pickupStatesCSV,
    deliveryStates: deliveryStatesCSV,
    trailerTypes: trailerTypeCSV,
    searchData: foundLaneSearchData ? foundLaneSearchData.map(getDetails) : [],
    loads: foundLaneSearchData,
    suggested: lane.isSuggested,
  } as MyLanesSearchData;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const cache = useQueryClient();
  const { queryParams } = useMyLanesQueryParams();

  const lanesQueryResult = useQuery('lanesV2', () => getLanes(), {
    refetchOnMount: true,
    staleTime: 20000,
  });

  const laneLimit = 15;

  let lanes = lanesQueryResult.data?.lanes?.slice(0, laneLimit) || [];
  let notificationPreference = lanesQueryResult.data?.notificationPreference;
  let isNotificationsDisabled =
    !(
      notificationPreference?.email ||
      notificationPreference?.text ||
      notificationPreference?.push
    ) ||
    (notificationPreference?.notificationFrequency === 'HOURLY' &&
      notificationPreference?.hourlySchedulePreference?.length === 0);

  const lanesSearchResult = useQuery(
    ['lanesSearchV2', JSON.stringify(lanes)],
    () => getSearchResultsForLanes(lanes || []),
    {
      enabled: lanes && lanes.length > 0,
    }
  );

  const { data: laneSearchResults } = lanesSearchResult;

  const { mutate: mutateAllLanes } = useMutation<
    Lanes,
    unknown,
    any,
    { previousLanes: Lanes | undefined }
  >(updateLanes, {
    onMutate: async (editedLanes: Lane[]) => {
      await cache.cancelQueries('lanesV2');

      const previousValue = cache.getQueryData<Lanes>('lanesV2');

      function updaterFn(old: any) {
        editedLanes.forEach((editedLane) => {
          const indexInAllLanes = old.lanes.findIndex(
            (oldLane: Lane) => oldLane.uid === editedLane.uid
          );

          if (indexInAllLanes === -1) {
            return;
          }

          old.lanes[indexInAllLanes] = editedLane;
        });
        return old;
      }

      cache.setQueryData<Lanes>('lanesV2', updaterFn);

      return { previousLanes: previousValue };
    },
    onError: (_err, _newLanes, context) => {
      if (context?.previousLanes) {
        cache.setQueryData<Lanes>('lanesV2', context.previousLanes);
      }
    },
  });

  //#region  mutations
  const { isLoading: isUpdatingLanes, mutate: mutateLanes } = useMutation<
    Lanes,
    unknown,
    Lane[],
    { previousLanes: Lanes | undefined }
  >(createOrUpdateUserLanes, {
    // cache the new object, we dont have the uid yet though
    onMutate: async (newLane: Lane[]) => {
      await cache.cancelQueries('lanesV2');
      const previousValue = cache.getQueryData<Lanes>('lanesV2');

      if (previousValue?.lanes)
        cache.setQueryData<Lanes>('lanesV2', (old) => ({
          ...old,
          lanes: [...newLane, ...(old?.lanes as Lane[])],
        }));
      else
        cache.setQueryData<Lanes>('lanesV2', {
          lanes: newLane,
        });

      return { previousLanes: previousValue };
    },
    // once we get the returned lanes, cache that, so the new lane has a uid
    onSuccess: (data) => {
      cache.cancelQueries('lanesV2');

      cache.setQueryData<Lanes>('lanesV2', (old) => ({
        ...old,
        lanes: data.lanes,
      }));
    },
    onError: (_err, _newLanes, context) => {
      if (context?.previousLanes) {
        cache.setQueryData<Lanes>('lanesV2', context.previousLanes);
      }
    },
  });

  const { mutate: mutateDeleteLanes } = useMutation<
    Lanes,
    unknown,
    any,
    { previousLanes: Lanes | undefined }
  >(deleteUserLanesById, {
    onMutate: async (deleteIds: { ids: string[] }) => {
      await cache.cancelQueries('lanesV2');

      const previousValue = cache.getQueryData<Lanes>('lanesV2');

      if (previousValue?.lanes) {
        let newLanes = previousValue.lanes.filter(
          (lane: any) => !deleteIds.ids.includes(lane.uid)
        );

        cache.setQueryData<Lanes>('lanesV2', (old) => ({
          ...old,
          lanes: [...newLanes],
        }));
      }
      return { previousLanes: previousValue };
    },
    onError: (_err, _newLanes, context) => {
      if (context?.previousLanes) {
        cache.setQueryData<Lanes>('lanesV2', context.previousLanes);
      }
    },
  });

  const { mutate: mutateNotificationsPreference } = useMutation<
    Lanes,
    unknown,
    { notificationPreference: NotificationPreferenceData }
  >(updateNotificationsPreference, {
    onMutate: async ({ notificationPreference }) => {
      await cache.cancelQueries('lanesV2');
      cache.setQueryData<{
        notificationPreference: NotificationPreferenceData;
      }>('lanes/updatePreference', {
        notificationPreference: notificationPreference,
      });

      cache.setQueryData<Lanes>('lanesV2', (old) => ({
        ...old,
        notificationPreference,
      }));
    },
    onError: (_err, _newLanes, context) => {},
  });

  // #endregion

  return {
    lanes,
    notificationPreference,
    isNotificationsDisabled,
    laneSearchData:
      laneSearchResults !== undefined && lanes
        ? lanes.map((x) => getLaneSearchResult(laneSearchResults, x))
        : [],
    isLoading: lanesQueryResult.isLoading,
    isLoadingLaneRoutes: lanesSearchResult.isLoading,
    getLane: (index: number): Lane | undefined => {
      logLane(lanes[index], 'View My Lane');
      return lanes[index];
    },
    addLaneV2: (laneToAdd: Lane) => {
      laneToAdd.createdAt = new Date().toISOString();
      logLane(laneToAdd, 'Add Lane');
      mutateLanes([laneToAdd]);
    },
    isUpdatingLanes: isUpdatingLanes,
    updateLanes: async (editedLanes: Lane[]) => {
      mutateAllLanes(editedLanes);
    },
    deleteLanes: (ids: string[]) => {
      let lanesToDelete = lanes.filter((lane: any) => ids.includes(lane.uid));
      lanesToDelete.forEach((lane) => {
        logLane(lane, 'Delete Lane');
      });
      mutateDeleteLanes({ ids });
    },
    updateNotificationsPreference: async (
      notificationPreference: NotificationPreferenceData
    ) => {
      mutateNotificationsPreference({
        notificationPreference: notificationPreference,
      });
    },
    getCurrentSelectedLane: () => {
      try {
        if (queryParams.lane) return lanes[Number(queryParams.lane) - 1];
      } catch {
        return undefined;
      }
    },
    getLanesAnalytics: (lane: Lane, load?: FindLoadsTableData) => {
      const { pathname } = window.location;
      let searchData = {};
      if (pathname.includes('myLanes') && laneSearchResults && lane) {
        searchData = getLaneSearchResult(laneSearchResults, lane).searchData;
        const location = getLocationLane(lane);
        let loadInfo = {};

        if (load) {
          loadInfo = {
            orderNumber: load?.id,
            origin: load?.stops[0].cityStateText,
            pickupDate: load?.stops[0].arrivalText,
            destination: load?.stops[load.stops.length - 1].cityStateText,
            equipmentType: load?.trailerType,
            binAmount: load?.price,
          };
        } else {
          //if load is null get order id from the url query
          try {
            const query = window.location.search;
            let theOrderNumber = '';
            if (query.includes('id=')) {
              theOrderNumber = query.split('?id=')[1].split('&')[0];
              loadInfo = {
                orderNumber: theOrderNumber,
              };
            }
          } catch {
            //nothing to do here.
          }
        }

        let result = {
          ...loadInfo,
          myLaneOrigin: location.pickup,
          myLaneDestination: location.delivery,
          myLaneEquipment: lane.trailerTypes.join(','),
          isUserGenerated: !lane?.isSuggested,
        };

        return result;
      }
      return {};
    },
  };
};
