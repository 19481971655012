import { RootState } from 'configureStore';
import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { CallBackProps } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SecondaryNavigation } from 'shamrock-clover-ui/dist/clover/components/SecondaryNavigation/SecondaryNavigation';
import CloverThemeProvider from 'shamrock-clover-ui/dist/clover/Theme/ThemeProvider';
import { setShowJoyride, useGetLanesQuery } from '../features/myLanes/state';
import { logMyLaneButtonClick } from '../features/myLanes/analytics/myLaneAnalytics';
import { updateUserMetadata } from '../utilities/userHttp';
import styled from '../theme';
import * as FirebaseUtils from '../utilities/firebaseUtils';
import Icon from './Icon';
import './subheader.css';
import { getCurrentUser } from '../utilities/authUtils';

interface SubHeaderProps {
  prefix?: string;
  v2?: boolean;
  header?: React.JSX.Element;
  tourDisabled?: boolean;
}
export const IconContainer = styled.span`
  display: inline-flex;
  height: 100%;
  align-items: center;
`;

export const ContactLink = styled.a`
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  text-decoration: none;
  vertical-align: super;
  color: ${({ theme }) => theme.colors.textFive};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;

export const TakeTourContainer = styled.div<{
  sidebarEnable?: boolean;
}>`
  display: flex;
  padding-right: ${({ sidebarEnable }) => (sidebarEnable ? '20px' : '64')};
  height: 40px;
  align-items: center;
  flex-direction: row;
  &:hover {
    text-decoration: none;
    text-decoration: underline;
    filter: brightness(0.7);
    text-decoration-color: #075c8e;
  }
`;

const buttonReset = {
  backgroundColor: 'transparent',
  border: 0,
  borderRadius: 0,
  outline: 'none',
  lineHeight: 1,
  padding: 8,
  fontFamily: "'proxima-nova', 'Roboto', Sans-serif",
  fontSize: 14,
  fontStyle: 'normal',
};
const styles = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    primaryColor: '#f04',
    textColor: '#333',
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    beaconSize: 36,
    zIndex: 100000,
    width: '350px',
    height: '170px',
  },
  tooltip: {
    color: '#000',
    fontFamily: "'proxima-nova', 'Roboto', Sans-serif",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    padding: '32px 32px 24px 32px',
  },
  tooltipFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  tooltipContainer: {
    textAlign: 'left',
  },
  tooltipContent: {
    padding: '0',
  },
  spotlight: {
    fill: '#F9F8F8',
    // filter: 'blur(7.5px)',
  },
  buttonClose: {},
  buttonNext: {
    ...buttonReset,
    backgroundColor: '#0091EA',
    borderRadius: '2px',
    color: '#fff',
    width: 'auto',
    height: '41px',
  },
  buttonSkip: {
    ...buttonReset,
    color: '#000',
    padding: '0px',
  },
  buttonBack: {
    ...buttonReset,
    color: '#0091EA',
    marginLeft: 'auto',
    position: 'absolute',
    padding: '0px',
  },
  floaterStyles: {
    close: {
      backgroundColor: 'transparent',
      border: 0,
      borderRadius: 0,
      color: '#555',
      fontSize: 0,
      height: 20,
      outline: 'none',
      padding: 10,
      position: 'absolute',
      right: 0,
      top: 0,
      width: 20,
      WebkitAppearance: 'none',
    },
  },
};

const V2Style = styled.div<{ sidebarEnable?: boolean }>`
  display: flex;
  position: ${({ sidebarEnable }) => sidebarEnable && 'relative'};
  top: ${({ sidebarEnable }) => sidebarEnable && -5};
`;

export const JoyRideCardMainText = styled.div`
  color: var(--Gray-Gray-90, #333);
  font-family: 'proxima-nova';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-bottom: 8px;
`;

export const JoyRideCardSubText = styled.span`
  color: var(--Gray-Gray-90, #333);
  font-family: 'proxima-nova';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const StyledSecondaryNavigation = styled(SecondaryNavigation)`
  #secondaryNav-element-0 {
    padding-left: 13px;
  }
`;

const SubHeader: FC<
  RouteComponentProps & SubHeaderProps & HTMLAttributes<HTMLDivElement>
> = ({ history, prefix, style, v2, header, tourDisabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loadsCount = useSelector<RootState, number>(
    (state) => state.loadsCount.loadsCount
  );

  const [navtabs, setNavTabs] = useState<any>([]);
  const [startJoyRide, setStartJoyRide] = React.useState(false);

  const currentUser = getCurrentUser();
  const { data: lanes } = useGetLanesQuery(currentUser?._id);

  useGetLanesQuery(currentUser?._id, {
    skip: !currentUser?._id,
  });

  const getToolTipMsg = () => {
    let tooltipMessage = '';
    if (lanes?.length === 0 || !lanes) {
      tooltipMessage = t('Add lanes for notifications');
    } else if (lanes.length > 0 && loadsCount === 0) {
      tooltipMessage = t('No loads available');
    } else if (lanes?.length > 0 && loadsCount > 0) {
      tooltipMessage = t('Loads available');
    }
    return tooltipMessage;
  };

  useEffect(() => {
    setNavTabs([
      {
        title: t('Load_Board'),
        onClick: handleFindLoadsClicked,
      },
      {
        title: t('My Lanes'),
        onClick: handleMyLanesClicked,
        badge: {
          content: `${loadsCount}`,
          color: loadsCount > 0 ? '#0091EA' : '#808080',
          tooltipText: getToolTipMsg(),
          tooltipPlacement: 'top',
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadsCount, lanes]);

  const handleJoyrideCallbacks = async (data: CallBackProps) => {
    const { action, index, lifecycle } = data;
    if (action === 'close' || action === 'skip') {
      setStartJoyRide(false);
    } else if (lifecycle === 'complete' && index === 2 && action === 'next') {
      setStartJoyRide(false);
      await updateUserMetadata({ metaData: { rtsProJoyRideSeen: true } });
    }
  };

  const rtsSteps = [
    {
      target: '#secondaryNav',
      disableBeacon: true,
      showSkipButton: true,
      content: (
        <div>
          <JoyRideCardMainText>
            {t(`We made some changes.`)}
          </JoyRideCardMainText>
          <JoyRideCardSubText>
            {t(`Take a quick tour to learn more`)}.
          </JoyRideCardSubText>
        </div>
      ),
    },
    {
      target: '#secondaryNav-element-0',
      disableBeacon: true,
      content: (
        <div>
          <JoyRideCardMainText>
            {t(`New Name, Same Functions`)}.
          </JoyRideCardMainText>
          <JoyRideCardSubText>
            {t(`Search, quote and book like normal.`)}.
          </JoyRideCardSubText>
        </div>
      ),
    },
    {
      target: '#secondaryNav-element-1',
      disableBeacon: true,
      showSkipButton: false,
      showProgress: false,
      styles: {
        buttonNext: {
          backgroundColor: '#72BF44',
        },
      },
      content: (
        <div>
          <JoyRideCardMainText>
            {t(`Find and book loads faster with My Lanes.`)}.
          </JoyRideCardMainText>
          <JoyRideCardSubText>
            {t(
              `The notification displays available loads on your preferred routes.`
            )}
            .
          </JoyRideCardSubText>
        </div>
      ),
    },
  ];

  const handleFindLoadsClicked = () => {
    history.push(`${prefix || ''}/findLoads`);
  };

  const handleMyLanesClicked = () => {
    logMyLaneButtonClick();
    history.push(`${prefix || ''}/myLanes`);
  };

  let startingIndex = 0;
  const path = history?.location?.pathname?.toLowerCase();
  switch (path.toLowerCase()) {
    case `${prefix || ''}/findLoads`.toLowerCase(): {
      startingIndex = 0;
      break;
    }
    case `${prefix || ''}/myLanes`.toLowerCase(): {
      startingIndex = 1;
      break;
    }
    default: {
      startingIndex = 0;
    }
  }

  const shouldShowTakeTour = () => {
    if (tourDisabled || startingIndex === 0) {
      return false;
    }

    return true;
  };

  const FeaturedSubHeader = () => {
    return (
      <div style={style}>
        <div style={{ paddingLeft: `${v2 ? 24 : 65}` }}>
          <StyledSecondaryNavigation
            id="secondaryNav"
            tabs={navtabs}
            startingIndex={startingIndex}
            horizontalPadding={'0'}
            v2={v2}
          />
        </div>
        <Joyride
          continuous
          run={startJoyRide}
          steps={rtsSteps}
          showProgress={true}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          disableScrolling={false}
          //@ts-ignore Joyride style types aren't up to date
          styles={styles}
          callback={handleJoyrideCallbacks}
          locale={{
            skip: t('skip_tour'),
            next: t('Next'),
            last: `${t('Done')} (3/3)`,
            back: t('Back'),
          }}
          floaterProps={{
            placement: 'bottom-start',
          }}
          spotlightPadding={0}
        />
        <V2Style sidebarEnable={v2}>
          {shouldShowTakeTour() && (
            <TakeTourContainer
              sidebarEnable={v2}
              onClick={() => {
                FirebaseUtils.logFirebaseEvent(
                  FirebaseUtils.FirebaseEvents.CLICK,
                  FirebaseUtils.FirebaseModules.FREIGHT,
                  FirebaseUtils.FirebasePages.LIST,
                  {
                    description: 'Take a tour',
                  }
                );
                dispatch(setShowJoyride(true));
              }}
            >
              <ContactLink>{t('take_a_tour')}</ContactLink>
              <IconContainer className="IconContainer">
                <Icon height={24} width={27} name="Tour" alt="take a tour" />
              </IconContainer>
            </TakeTourContainer>
          )}
          {header}
        </V2Style>
      </div>
    );
  };

  return (
    <CloverThemeProvider>
      <FeaturedSubHeader />
    </CloverThemeProvider>
  );
};

export default withRouter(SubHeader);
