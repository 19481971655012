import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router';
import { DeadheadQueryParams } from 'features/findLoads/findLoadsTypes';
import isEmpty from 'lodash/isEmpty';

export const useQueryParams = <T extends {}>() => {
  const { search } = useLocation();
  const { push } = useHistory();

  const addQueryParams = (paramsToAdd: T) => {
    const query = queryString.parse(window.location.search);

    push({
      search: queryString.stringify({
        ...query,
        ...paramsToAdd,
      }),
    });
  };

  const clearQueryParams = () => {
    const query = queryString.parse(window.location.search);

    if (!isEmpty(query)) {
      push({
        search: '',
      });
    }
  };

  const setQueryParams = (paramsToAdd: T) => {
    push({
      search: queryString.stringify({
        ...paramsToAdd,
      }),
    });
  };

  return {
    addQueryParams,
    clearQueryParams,
    setQueryParams,
    queryParams: queryString.parse(search) as T,
  };
};

export const useAuthQueryParams = () =>
  useQueryParams<{
    token: string;
    email: string;
  }>();

export const useDeadheadQueryParams = () =>
  useQueryParams<DeadheadQueryParams>();

export const useDrawerQueryParams = () =>
  useQueryParams<{ id?: string; quotes?: string; booked?: string }>();

export const useMyLanesQueryParams = () =>
  useQueryParams<{ lane?: string; id?: string }>();
