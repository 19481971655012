import React, {
  useState,
  createContext,
  useContext,
  SyntheticEvent,
} from 'react';
import MaterialSnackbar from '@material-ui/core/Snackbar';
import styled from '../theme';
import { SnackbarContent } from '@material-ui/core';

interface ShowSnackbarProps {
  message: string;
  type: 'info' | 'error';
}

interface SnackbarProps {
  message: string;
  type: 'info' | 'error';
  isOpen: boolean;
}

const SnackbarContext = createContext<any | undefined>(undefined);

const SnackbarStyled = styled(MaterialSnackbar)<{ type: 'info' | 'error' }>`
  .MuiSnackbarContent-root {
    background-color: ${({ theme, type }) =>
      type === 'info' ? theme.colors.highlightOne : theme.colors.error};
  }
  .MuiSnackbar-anchorOriginBottomCenter {
    bottom: 34px;
  }
`;

export const SnackbarProvider = ({ children }: any) => {
  const [snack, setSnackbar] = useState<SnackbarProps>();

  const showSnackbar = (snackbar: ShowSnackbarProps) => {
    setSnackbar({ ...snackbar, isOpen: true });
  };

  const handleClose = (_event: SyntheticEvent<any>, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (snack) {
      setSnackbar({ ...snack, isOpen: false });
    }
  };

  const value = {
    showSnackbar,
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {snack && (
        <SnackbarStyled
          type={snack?.type}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={snack.isOpen}
          onClose={handleClose}
          autoHideDuration={4000}
        >
          <SnackbarContent message={snack.message}></SnackbarContent>
        </SnackbarStyled>
      )}
    </SnackbarContext.Provider>
  );
};

const useSnackbars = (): {
  showSnackbar: (snackbar: ShowSnackbarProps) => void;
} => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbars must be used within the SnackbarProvider');
  }

  return context;
};

export default useSnackbars;
