// This Routes file is unused, src/federation/federated-router.tsx has the real routes

import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner';
import Header from './components/Header';
import Footer from './components/Footer';
import { Flex } from './components/Flex';
import { ThemeProvider } from 'emotion-theming';
import styled, { Theme, GlobalCss, MaterialTheme } from './theme';
import DateFnsUtils from '@date-io/date-fns';
import CloverThemeProvider from 'shamrock-clover-ui/dist/clover/Theme/ThemeProvider';
import { useAuthQueryParams } from './hooks/useQueryParams';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from './hooks/useSnackbar';
import {
  authenticateAndSaveUser,
  isAuthenticated,
  redirectToLogin,
} from './utilities/authUtils';

const LoadboardTable = React.lazy(() => import('./features/findLoads'));
const MyLanes = React.lazy(() => import('./features/myLanes'));

const AppContainer = styled(Flex)`
  position: relative;
  overflow: auto;
`;

const ContentContainer = styled.div`
  padding: 32px 64px 0px 64px;
  min-width: 750px;
`;

const Routes: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { queryParams } = useAuthQueryParams();

  useEffect(() => {
    const authenticateUser = async () => {
      // if we have an email and token we always need to go revalidate
      // a new token could have been generated when actinium routes to our site
      if (queryParams.email && queryParams.token) {
        try {
          await authenticateAndSaveUser(queryParams.token, queryParams.email);
          setIsLoading(false);
          return;
        } catch (error) {
          redirectToLogin();
        }
      }

      if (isAuthenticated()) {
        setIsLoading(false);
        return;
      }
      // when we logout actinium will redirect here without the token and email
      // and expects us to redirect back to the proper login url
      redirectToLogin();
    };
    authenticateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <CloverThemeProvider>
        <MaterialThemeProvider theme={MaterialTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <GlobalCss />
            {isLoading ? (
              <LoadingSpinner></LoadingSpinner>
            ) : (
              <>
                <Header />
                <AppContainer
                  flexDirection="column"
                  justifyContent="space-between"
                  height="calc(100% - 104px)"
                >
                  <ContentContainer>
                    <React.Suspense fallback={<LoadingSpinner />}>
                      <SnackbarProvider>
                        <Switch>
                          <Route path="/findLoads" component={LoadboardTable} />
                          <Route path="/myLanes" component={MyLanes} />
                          <Redirect from="/" to="/findLoads" />
                        </Switch>
                      </SnackbarProvider>
                    </React.Suspense>
                  </ContentContainer>
                  <Footer />
                </AppContainer>
              </>
            )}
          </MuiPickersUtilsProvider>
        </MaterialThemeProvider>
      </CloverThemeProvider>
    </ThemeProvider>
  );
};

export default Routes;
