import { logFirebaseUser } from './firebaseUtils';
import * as process from 'process';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';

const USER_URL = process.env.REACT_APP_USER_URL || '';
const LOADBOARD_PERMISSION = 'ryantrans.Loadboard';
const LOADBOARD_URL = process.env.REACT_APP_LOADBOARD_URL || '';

export interface UserInfo {
  token: string;
  user: string;
  id: string;
}

export interface CurrentUser {
  company: string;
  createdOn: string;
  crmId: string;
  deviceIds: string[];
  email: string;
  factoring_status: string;
  firstname?: string;
  lastname: string;
  note: string;
  permissions?: { name: string; status: string }[];
  pin: string;
  roles: string[];
  status: string;
  user_type: string;
  _id: string;
  password: string;
  phone?: string;
  mcleod_customer_id: string[];
  bridge_id: string[];
  device_ids: string[];
  mc_number?: string;
  metaData: UserMetadataI;
}

export type UserMetadata = {
  metaData: UserMetadataI;
};

interface NotificationMessage {
  congratulations?: boolean;
  remember?: boolean;
  seenNotificationsSettings?: boolean;
}
interface UserMetadataI {
  rtsProJoyRideSeen?: boolean;
  notificationMessage?: NotificationMessage;
}

export const getCurrentUser = () => {
  const currentUser = LocalAuth.getUser() as any;

  // isEmpty
  return (Object.keys(currentUser).length === 0 ? undefined : currentUser) as
    | CurrentUser
    | undefined;
};

export const getAuthToken = () => {
  return LocalAuth.getTokenValue();
};

// Freight - Book It Now role allows the user to view the load board, view prices, submit quotes and submit BINs
export const getHasBookItNowPermissions = () => {
  const user = LocalAuth.getUser();

  const hasPermission: boolean =
    user &&
    user.permissions &&
    Array.isArray(user.permissions) &&
    user.permissions.filter((x) => x.name === 'ryantrans.LoadboardBook')
      .length > 0
      ? true
      : false;

  return hasPermission;
};

export const getHasLoadManagementPermission = () => {
  const user = LocalAuth.getUser();

  return (
    user &&
    user.permissions &&
    Array.isArray(user.permissions) &&
    user.permissions.filter((x) => x.name === 'loadManagement.User').length > 0
  );
};

// Freight role only allows the user to view the load board
export const getHasFreightPermission = () => {
  const user = LocalAuth.getUser();

  return (
    user &&
    user.permissions &&
    Array.isArray(user.permissions) &&
    user.permissions.filter((x) => x.name === 'ryantrans.Loadboard').length > 0
  );
};

// Freight with Rates role only allows the user to view the load board and view prices
export const getHasFreightRatesPermission = () => {
  const user = LocalAuth.getUser();

  return (
    user &&
    user.permissions &&
    Array.isArray(user.permissions) &&
    user.permissions.filter((x) => x.name === 'ryantrans.Loadprices').length > 0
  );
};

// Freight Quoting role only allows the user to view the load board, view prices and submit quotes
export const getHasFreightQuotingPermission = () => {
  const user = LocalAuth.getUser();

  return (
    user &&
    user.permissions &&
    Array.isArray(user.permissions) &&
    user.permissions.filter((x) => x.name === 'ryantrans.Loadquoting').length >
      0
  );
};

const getUser = async (token: string, userName: string) => {
  const response = await fetch(`${USER_URL}user/user/` + userName, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return (await response.json()) as CurrentUser;
};

export const authenticateAndSaveUser = async (
  token: string,
  userName: string
) => {
  LocalAuth.setToken({ email: userName, token });

  const user = await getUser(token, userName);
  logFirebaseUser(user._id, user.user_type);
  LocalAuth.setUser(user as any);
};

export const isAuthenticated = () => {
  if (!!(LocalAuth.getTokenValue() && LocalAuth.getUser())) {
    const user = LocalAuth.getUser() as unknown as CurrentUser;
    const isValid =
      user.permissions &&
      user.permissions.some((x) => x.name === LOADBOARD_PERMISSION);

    if (!isValid) {
      return false;
    }
    logFirebaseUser(user?._id, user?.user_type);
    return true;
  }
  return false;
};

export const getUserEmail = () => {
  const user = getCurrentUser();
  if (user) {
    return user.email;
  } else return '';
};

export const redirectToLogin = () => {
  LocalAuth.redirectToLogin(LOADBOARD_URL);
};
