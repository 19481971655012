import * as React from 'react';
import { Spinner } from 'shamrock-clover-ui/dist/clover/components/Spinner/Spinner';
import { Flex } from './Flex';

interface Props {
  size?: string;
  height?: string;
  width?: string;
  useWait?: boolean;
}

const SpinnerWithWait: React.FunctionComponent<Props> = ({
  size,
  height,
  width,
  useWait = true,
}) => {
  const [showSpinner, setShowSpinner] = React.useState(false);

  React.useEffect(() => {
    if (useWait) {
      const timeout = setTimeout(() => {
        setShowSpinner(true);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
    setShowSpinner(true);
  });

  return showSpinner ? (
    <Flex
      alignItems="center"
      justifyContent="center"
      height={height || '100%'}
      width={width || '100%'}
    >
      <Spinner size={size}></Spinner>
    </Flex>
  ) : null;
};

export default SpinnerWithWait;
