import * as Ably from 'ably';
import http from '../utilities/httpUtils';
import { getCurrentUser } from '../utilities/authUtils';

const ABLY_AUTH_SERVICE_URL = process.env.REACT_APP_ABLY_AUTH_SERVICE_URL;
const ABLY_QUOTE_CHANNEL = process.env.REACT_APP_ABLY_QUOTE_CHANNEL;

const ablyAuthCallback =
  (
    getAblyWildcardToken: (
      query?: {} | undefined
    ) => Promise<Ably.Types.TokenDetails>
  ) =>
  async (
    _tokenParams: Ably.Types.TokenParams,
    callback: (
      error: Ably.Types.ErrorInfo | string,
      tokenRequestOrDetails:
        | Ably.Types.TokenDetails
        | Ably.Types.TokenRequest
        | string
    ) => void
  ) => {
    try {
      const token = await getAblyWildcardToken();
      callback('', token);
    } catch (error) {
      callback(error as Ably.Types.ErrorInfo, '');
    }
  };

export const initAbly = (channel?: string) => {
  const currentUser = getCurrentUser();

  const channelName =
    channel && channel === 'ryanstran:lanesCron'
      ? `${channel}:${currentUser?._id}`
      : ABLY_QUOTE_CHANNEL;

  const getAblyWildcardToken = http.getWithURL<Ably.Types.TokenDetails>(
    `${ABLY_AUTH_SERVICE_URL}/${channelName}`
  );

  if (!currentUser && process.env.NODE_ENV !== 'test') {
    throw new Error(
      'Cannot initialize ably without a currently logged in user.'
    );
  }
  const ably = new Ably.Realtime.Promise({
    authCallback: ablyAuthCallback(getAblyWildcardToken),
  });
  return ably;
};
