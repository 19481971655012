import queryString from 'query-string';
import { auth0Context, getAuthToken, redirectToLogin } from './authUtils';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const quotesApiURL = process.env.REACT_APP_API_BASE_QUOTE_URL;

const defaultOptions = async () => ({
  headers: {
    Authorization: `Bearer ${await getAuthToken()}`,
    'Content-Type': 'application/json',
  },
});

const handleResponse = async (response: Response) => {
  if (response.status === 401 || response.status === 403) {
    if (auth0Context.isAuthenticated) {
      auth0Context.logout &&
        (await auth0Context.logout({
          logoutParams: { returnTo: window.location.pathname },
        }));
    } else {
      redirectToLogin();
    }
    return;
  }

  const json = await response.json();
  if (!response.ok) {
    throw new Error(json.message);
  }
  return json;
};

const get =
  <T extends unknown, Q extends unknown = {}>(url: string, service?: string) =>
  async (query?: Q): Promise<T> => {
    const baseURL =
      service !== undefined && service === 'quoteService'
        ? quotesApiURL
        : BASE_URL;
    const formattedUrl = query
      ? `${baseURL}/${url}?${queryString.stringify(query as any)}`
      : `${baseURL}/${url}`;

    return handleResponse(await fetch(formattedUrl, await defaultOptions()));
  };

const getWithURL =
  <T extends unknown, Q extends unknown = {}>(url: string) =>
  async (query?: Q): Promise<T> => {
    const formattedUrl = query
      ? `${url}?${queryString.stringify(query as any)}`
      : `${url}`;
    return handleResponse(await fetch(formattedUrl, await defaultOptions()));
  };

const post =
  <T extends unknown, B extends unknown>(url: string, service?: string) =>
  async (body: B): Promise<T> => {
    const baseURL =
      service !== undefined && service === 'quoteService'
        ? quotesApiURL
        : BASE_URL;
      const getDefaultOptions = await defaultOptions()
    return handleResponse(
      await fetch(`${baseURL}/${url}`, {
        ...getDefaultOptions,
        method: 'POST',
        body: JSON.stringify(body),
      })
    );
  };

const put =
  <T extends unknown, B extends unknown>(url: string, service?: string) =>
  async (body: B): Promise<T> => {
    const baseURL =
      service !== undefined && service === 'quoteService'
        ? quotesApiURL
        : BASE_URL;
    const getDefaultOptions = await defaultOptions()
    return handleResponse(
      await fetch(`${baseURL}/${url}`, {
        ...getDefaultOptions,
        method: 'PUT',
        body: JSON.stringify(body),
      })
    );
  };

const _delete =
  <T extends unknown, B extends unknown>(url: string, service?: string) =>
  async (body: B): Promise<T> => {
    const baseURL =
      service !== undefined && service === 'quoteService'
        ? quotesApiURL
        : BASE_URL;
    const getDefaultOptions = await defaultOptions()
    return handleResponse(
      await fetch(`${baseURL}/${url}`, {
        ...getDefaultOptions,
        method: 'DELETE',
        body: JSON.stringify(body),
      })
    );
  };

export default {
  get,
  getWithURL,
  put,
  post,
  _delete,
};
