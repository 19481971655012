import {
  FirebaseEvents,
  FirebaseModules,
  FirebasePages,
  logFirebaseEvent,
} from '../../utilities/firebaseUtils';
import { Lane } from './myLanesTypesV2';

export const logLane = (lane: Lane, description: string) => {
  logFirebaseEvent(
    FirebaseEvents.CLICK,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      ...lane,
      description,
    }
  );
};
