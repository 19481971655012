import { DeadheadSearchQuery, DeadheadSearch } from './findLoadsTypes';
import http from '../../utilities/httpUtils';

export const getLoads = async (query: DeadheadSearchQuery) => {
  const { data } = await http.get<
    { data: DeadheadSearch[] },
    DeadheadSearchQuery
  >('orders/deadhead')(query);

  return data;
};

export const getSimiliarLoads = async (query: DeadheadSearchQuery) => {
  const { data } = await http.get<
    { data: DeadheadSearch[] },
    DeadheadSearchQuery
  >('orders/deadhead/similiar')(query);

  return data;
};

export const getIsAvailable = async (id: string) => {
  const { data } = await http.get<{ data: { isAvailable: boolean } }>(
    `orders/deadhead/${id}/isAvailable`
  )();

  return data;
};
