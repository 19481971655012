import * as React from 'react';
import styled from '../theme';
import RightArrow from '../assets/images/RightArrow.svg';
import RtsProWhite from '../assets/images/RtsProWhite.svg';
import Search from '../assets/images/Search.svg';
import Refresh from '../assets/images/Refresh.svg';
import Clock from '../assets/images/Clock.svg';
import ClockLight from '../assets/images/ClockLight.svg';
import RowArrow from '../assets/images/RowArrow.svg';
import Close from '../assets/images/Close.svg';
import Pickup from '../assets/images/Pickup.svg';
import Delivery from '../assets/images/Delivery.svg';
import Phone from '../assets/images/Phone.svg';
import Email from '../assets/images/Email.svg';
import RightArrowBlack from '../assets/images/RightArrowBlack.svg';
import Price from '../assets/images/Price.svg';
import SignUp from '../assets/images/SignUp.svg';
import MapPin from '../assets/images/MapPin.svg';
import BackArrow from '../assets/images/BackArrow.svg';
import Cancel from '../assets/images/Cancel.svg';
import Save from '../assets/images/Save.svg';
import Trash from '../assets/images/Trash.svg';
import DownArrowBlack from '../assets/images/DownArrowBlack.svg';
import DollarSignDisabled from '../assets/images/DollarSignDisabled.svg';
import DollarSignEnabled from '../assets/images/DollarSignEnabled.svg';
import RightBigArrowBlack from '../assets/images/RightBigArrowBlack.svg';
import RightBigArrowBlue from '../assets/images/RightBigArrowBlue.svg';
import RightBigArrowDisabled from '../assets/images/RightBigArrowDisabled.svg';
import Booked from '../assets/images/Booked.svg';
import Accepted from '../assets/images/Accepted.svg';
import Verifying from '../assets/images/Verifying.svg';
import CancelRed from '../assets/images/CancelRed.svg';
import Check from '../assets/images/Check.svg';
import BecomeRyanCarrier from '../assets/images/BecomeRyanCarrier.svg';
import DollarOffMedium from '../assets/images/DollarOffMedium.svg';
import DollarOffMediumBlue from '../assets/images/DollarOffMediumBlue.svg';
import NotificationOn from '../assets/images/NotificationsOn.svg';
import NotificationOff from '../assets/images/NotificationsOff.svg';
import Tour from '../assets/images/Tour.svg';
import NotificationIllustration from '../assets/images/NotificationIllustration.svg';
import LanesEmptyStateMap from '../assets/images/LanesEmptyStateMap.svg';
import CapacityStars from '../assets/images/CapacityStars.svg';
import NotificationSettings from '../assets/images/NotificationSettings.svg';
import EditPencil from '../assets/images/EditPencil.svg';
import PlusBlue from '../assets/images/PlusBlue.svg';
import Plus from '../assets/images/Plus.svg';

export type ImageName =
  | 'RtsProWhite'
  | 'Search'
  | 'Refresh'
  | 'Clock'
  | 'RowArrow'
  | 'Close'
  | 'Pickup'
  | 'Delivery'
  | 'Phone'
  | 'Email'
  | 'RightArrow'
  | 'RightArrowBlack'
  | 'Price'
  | 'SignUp'
  | 'MapPin'
  | 'BackArrow'
  | 'ClockLight'
  | 'Cancel'
  | 'Trash'
  | 'Save'
  | 'DownArrowBlack'
  | 'DollarSignDisabled'
  | 'DollarSignEnabled'
  | 'RightBigArrowBlack'
  | 'RightBigArrowBlue'
  | 'RightBigArrowDisabled'
  | 'Booked'
  | 'Accepted'
  | 'Verifying'
  | 'CancelRed'
  | 'Check'
  | 'BecomeRyanCarrier'
  | 'DollarOffMedium'
  | 'DollarOffMediumBlue'
  | 'NotificationOn'
  | 'NotificationOff'
  | 'Tour'
  | 'NotificationIllustration'
  | 'LanesEmptyStateMap'
  | 'CapacityStars'
  | 'NotificationSettings'
  | 'EditPencil'
  | 'PlusBlue'
  | 'Plus';

const Images: { [key in ImageName]: string } = {
  RightArrow,
  RtsProWhite,
  Search,
  Refresh,
  Clock,
  RowArrow,
  Close,
  Pickup,
  Delivery,
  Phone,
  Email,
  RightArrowBlack,
  Price,
  SignUp,
  MapPin,
  BackArrow,
  ClockLight,
  Cancel,
  Trash,
  Save,
  DownArrowBlack,
  DollarSignDisabled,
  DollarSignEnabled,
  RightBigArrowBlack,
  RightBigArrowBlue,
  RightBigArrowDisabled,
  Booked,
  Accepted,
  Verifying,
  CancelRed,
  Check,
  BecomeRyanCarrier,
  DollarOffMedium,
  DollarOffMediumBlue,
  NotificationOn,
  NotificationOff,
  Tour,
  NotificationIllustration,
  LanesEmptyStateMap,
  CapacityStars,
  NotificationSettings,
  EditPencil,
  PlusBlue,
  Plus,
};

interface Props {
  name: ImageName;
  alt: string;
  height?: number;
  width?: number;
  margin?: string;
  onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

interface ImageStyledProps {
  height?: number;
  width?: number;
  margin?: string;
  onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

const Image = styled.img<ImageStyledProps>`
  height: ${({ height }) => height && `${height}px`};
  width: ${({ width }) => width && `${width}px`};
  margin: ${({ margin }) => margin && `${margin}`};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;

const Icon: React.FunctionComponent<Props> = ({ name, alt, ...rest }) => {
  return <Image {...rest} src={Images[name]} alt={alt} />;
};

export default Icon;
