import { UserMetadata } from 'utilities/authUtils';
import http from '../../utilities/httpUtils';
import { Lanes, Lane, SuggestedLane } from './myLanesTypes';

export const getLanes = http.get<Lanes, undefined>('lanes');

export const updateLanesEmailEnabled = http.put<
  Lanes,
  { emailEnabled: boolean }
>('lanes/emailEnabled');

export const updateSuggestedLanes = (lane: Lane) => {
  let suggestedLane: SuggestedLane = lane;
  return http.put<SuggestedLane, { suggestedLane: SuggestedLane }>(
    'lanes/disable-suggested'
  )({ suggestedLane });
};
export const createOrUpdateUserLanes = (lanes: Lane[]) => {
  return http.put<Lanes, { lanes: Lane[] }>('lanes')({ lanes });
};

export const createOrUpdateLanes = async (alllanes: any) => {
  const { lanes, suggestedLanes } = alllanes;

  if (suggestedLanes && suggestedLanes.length > 0)
    await Promise.all(suggestedLanes.map(updateSuggestedLanes));

  const wholeLanesObj = await http.put<Lanes, { lanes: Lane[] }>('lanes')({
    lanes,
  });

  return wholeLanesObj;
};

export const updatePushNotificationsEnabled = http.put<
  Lanes,
  { pushNotificationEnabled: boolean }
>('lanes/pushEnabled');

export const updateNotificationPreferences = http.put<
  Lanes,
  { notificationPreference: number[] }
>('lanes/updatePreference');

export const updateUserMetada = http.put<unknown, UserMetadata>(
  'update-user-metadata'
);
