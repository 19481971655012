import { useQuery } from 'react-query';
import { getFeature } from '../utilities/featureUtils';

// feature flag hook
const useFeatureFlag = (id: string) => {
  return useQuery([id, id], () => getFeature(id), {
    staleTime: Infinity,
  });
};

export const useToggleBookItNowFeatureFlag = () => {
  let results = useFeatureFlag('RTD-1154-Mobile');
  return { ...results, isBookItNowToggleFeatureEnabled: results.data };
};

// feature for suggested lanes
export const useSuggestedLanes = () => {
  let results = useFeatureFlag('RTD-1614');
  return { ...results, isSuggestedLanesEnabled: results.data };
};

// Freight Admin Enhancements
export const useQuoteEnhancementsFeatureFlag = () => {
  let results = useFeatureFlag('RTD-1998');
  return { ...results, isQuoteEnhancementsEnabled: results.data };
};

export const useBookedLoadsAlertFeatureFlag = () => {
  let results = useFeatureFlag('RTD-2317');

  return {
    ...results,
    isBookedLoadsAlertEnabled: results.data,
  };
};

export const useMyLanesEnhancementsFeatureFlag = () => {
  let results = useFeatureFlag('RTD-3119');

  return {
    ...results,
    isMyLanesEnhancementsEnabled: results.data,
  };
};

// Ignore Outage Dialog
export const useIgnoreOutageDialogFeatureFlag = () => {
  let results = useFeatureFlag('RTD-2941');
  return { ...results, isIgnoreOutageDialogEnabled: results.data };
};

export const useNewFuel4073 = () => {
  let results = useFeatureFlag('FUEL-4073');
  return { ...results, isNewFuelEnabled: results.data };
};
