import { getCurrentUser } from './authUtils';

declare const firebase: any;

const cache: CachedItem[] = [];
let isLoaded = false;
let errorLoadingScript = false;
let userLatitude = 0;
let userLongitude = 0;
let userDataSet = false;

export enum FireBaseFunc {
  LOG_FIREBASE_SCREEN_NAME = 'logFirebaseScreenName',
  LOG_FIREBASE_USER = 'logFirebaseUser',
  LOG_PAGE_VIEW = 'logPageView',
  LOG_FIREBASE_EVENT = 'logFirebaseEvent',
}

interface CachedItem {
  firebaseFunc: FireBaseFunc;
  params: Array<any>;
}

interface FirebaseConfigProps {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const loadScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.src = src;
    script.addEventListener('load', (event) => {
      resolve();
    });
    script.addEventListener('error', (event) => {
      reject();
    });
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  });
};

export enum FirebaseParameters {
  MODULE = 'module',
  PAGE = 'page',
  USER_ID = 'UserID',
  USER_TYPE = 'userType',
  TYPE = 'type',
  DATE = 'date',
  ITEM_LIST_COUNT = 'item_list_count',
  ID = 'id',
  DESTINATION = 'destination',
  NAME = 'name',
  LAT = 'lat',
  LNG = 'lng',
  SEARCH_NAME = 'search_name',
  PRICE = 'price',
  VALUE = 'value',
  SEARCH_TYPE = 'search_type',
  ROUTE = 'route',
  STARTING_LOCATION = 'starting_location',
  ENDING_LOCATION = 'ending_location',
  FEATURED_STATIONS = 'featured_stations',
  TYPES = 'types',
}

export enum FirebasePages {
  SEARCH_LOADS = 'Search Loads',
  POINTE_OF_ENTRY = 'POE',
  LIST = 'List',
  MAP = 'Map',
  DETAILS = 'Details',
  MY_LANES = 'MyLanes',
  MY_QUOTES = 'MyQuotes',
}

export enum FirebaseModules {
  FREIGHT = 'Freight',
}

export enum FirebaseEvents {
  SEARCH = 'search',
  EULA_ACCEPTED = 'eula_accepted',
  DETAILS_VIEW = 'details_view',
  SET_FAVORITE = 'set_favorite',
  CLICK = 'click',
  TAKE_PHOTO = 'take_photo',
  CHOOSE_LIBRARY = 'choose_library',
  CHOOSE_PDF = 'choose_pdf',
  TAB_SELECTED = 'tab_selected',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  SUBMIT = 'submit',
  SAVE = 'save',
  SORT = 'sort',
  FILTER = 'filter',
  SELECT = 'select',
  ENTER = 'enter',
  EXIT = 'exit',
  RECEIVED = 'received',
  EXPORT = 'export',
  UPLOAD = 'upload',
  LOAD_SEARCH = 'loadboard_search',
  POE_TRAILER = 'poe_trailer',
  MAP_CLICK = 'map_click',
  SEARCH_THIS_AREA = 'search_area',
  CALL = 'call',
  EMAIL = 'email',
  BACKHAUL_CLICK = 'backhaul_click',
  SEARCH_RESULTS = 'loadboardSearch',
  DETAIL_CLICK = 'detail_click',
  PAGE_VIEW = 'page_view',
  NOTIFY_ME = 'notifyMe',
  ADD_LANE = 'addLane',
  EDIT_LANE = 'editLane',
  DELETE_LANE = 'deleteLane',
  QUOTE_SUBMISSION = 'quoteSubmission',
  QUOTE_CANCELLATION = 'quoteCancellation',
  QUOTE_COUNTER = 'quoteCounter',
  QUOTE_DISCARD = 'quoteDiscard',
  QUOTE_DECLINE = 'quoteDecline',
  QUOTE_ACCEPT = 'quoteAccept',
  EDIT_QUOTE = 'editQuote',
  MY_QUOTES_CLICK = 'myQuotesClick',
  MY_QUOTES_DETAIL_CLICK = 'detailClick',
  QUOTE_BOOK_IT_NOW = 'bookItNowSubmission',
}

export const logFirebaseScreenName = (page: FirebasePages) => {
  if (cacheEvent(FireBaseFunc.LOG_FIREBASE_SCREEN_NAME, [page])) {
    return;
  }
  firebase.analytics().setCurrentScreen(page);
};

export const logFirebaseUser = (
  userId: string | undefined,
  userType?: string | undefined
) => {
  if (cacheEvent(FireBaseFunc.LOG_FIREBASE_USER, [userId, userType])) {
    return;
  }
  firebase
    .analytics()
    .setUserProperties({ [FirebaseParameters.USER_ID]: userId });
  firebase.analytics().setUserId(userId);

  if (userType) {
    firebase
      .analytics()
      .setUserProperties({ [FirebaseParameters.USER_TYPE]: userType });
  }

  userDataSet = true;
};

export const logFirebaseEvent = (
  event: FirebaseEvents,
  module: FirebaseModules,
  page: FirebasePages,
  customParams: any
) => {
  if (
    cacheEvent(FireBaseFunc.LOG_FIREBASE_EVENT, [
      event,
      module,
      page,
      customParams,
    ])
  ) {
    return;
  }

  if (!userDataSet) {
    const user = getCurrentUser();
    logFirebaseUser(user?._id, user?.user_type);
  }
  let mainParams = {
    module: module,
    page: page,
    userLatitude,
    userLongitude,
  };

  let allParams = Object.assign({}, customParams, mainParams);

  firebase.analytics().logEvent(event, allParams);
};

export const logPageView = (page: FirebasePages) => {
  if (cacheEvent(FireBaseFunc.LOG_PAGE_VIEW, [page])) {
    return;
  }
  if (!userDataSet) {
    const user = getCurrentUser();
    logFirebaseUser(user?._id, user?.user_type);
  }
  firebase.analytics().logEvent(FirebaseEvents.PAGE_VIEW, {
    module: 'Load',
    page,
  });
};

const loadCachedEvents = () => {
  if (cache.length > 0) {
    cache.forEach((e) => {
      switch (e.firebaseFunc) {
        case FireBaseFunc.LOG_FIREBASE_EVENT:
          logFirebaseEvent(e.params[0], e.params[1], e.params[2], e.params[3]);
          break;
        case FireBaseFunc.LOG_FIREBASE_SCREEN_NAME:
          logFirebaseScreenName(e.params[0]);
          break;
        case FireBaseFunc.LOG_FIREBASE_USER:
          logFirebaseUser(e.params[0], e.params[1]);
          break;
        case FireBaseFunc.LOG_PAGE_VIEW:
          logPageView(e.params[0]);
          break;
      }
    });
  }
};

const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        userLatitude = latitude;
        userLongitude = longitude;
      },
      () => {}
    );
  }
};

export const init = async (firebaseConfig: FirebaseConfigProps) => {
  getLocation();

  await loadScript('https://www.gstatic.com/firebasejs/7.16.0/firebase-app.js');
  await loadScript(
    'https://www.gstatic.com/firebasejs/7.16.0/firebase-analytics.js'
  );

  isLoaded = true;
  firebase.initializeApp(firebaseConfig);
  loadCachedEvents();
};

const cacheEvent = (
  firebaseFunc: FireBaseFunc,
  params: Array<any>
): boolean => {
  if (isLoaded === false && errorLoadingScript === false) {
    cache.push({ firebaseFunc, params });
    return true;
  }
  // if we fail to load script we do not want
  // to cache or try to log
  if (errorLoadingScript) return true;
  return false;
};
