import * as Ably from 'ably';
import { initAbly } from '../ably/ablyHttp';

let ably: Ably.Types.RealtimePromise;
const ABLY_QUOTE_CHANNEL = process.env.REACT_APP_ABLY_QUOTE_CHANNEL;

export const useAbly = (channel?: string) => {
  if (!ably) {
    ably = initAbly(channel);
  }

  const publishMessage = async (type: string, data: object): Promise<void> => {
    try {
      const channel = await ably.channels.get(ABLY_QUOTE_CHANNEL as string);
      await channel.publish(type, data);

      console.log('=========================================\n');

      if (process.env.NODE_ENV !== 'production') {
        console.table({ type, data });
      }
    } catch (error) {
      console.log('Error connecting Ably', error);
    }
  };
  return {
    ably,
    publishMessage,
  };
};
