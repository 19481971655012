import {
  logFirebaseEvent,
  FirebaseEvents,
  FirebasePages,
  FirebaseModules,
} from '../../../utilities/firebaseUtils';

export const logMyLaneButtonClick = () => {
  logFirebaseEvent(
    FirebaseEvents.CLICK,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      description: 'view My Lanes',
    }
  );
};

export const logMyLaneEditButtonClick = () => {
  logFirebaseEvent(
    FirebaseEvents.CLICK,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      description: 'Edit Lanes',
    }
  );
};

export const logMyLaneEditSaveChangesButtonClick = () => {
  logFirebaseEvent(
    FirebaseEvents.EDIT_LANE,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      description: 'Save Edit Lanes',
    }
  );
};

export const logMyLaneEditCancelChangesButtonClick = () => {
  logFirebaseEvent(
    FirebaseEvents.CLICK,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      description: 'Cancel Edit Lanes',
    }
  );
};

export const logMyLane_OnLaneClicked = (searchData: any) => {
  const logObj = {
    myLaneOrigin: searchData['myLaneOrigin'],
    myLaneDestination: searchData['myLaneDestination'],
    myLaneEquipment: searchData['myLaneEquipment'],
    isUserGenerated: searchData['isUserGenerated'],
    description: 'View My Lanes Loads',
  };

  logFirebaseEvent(
    FirebaseEvents.CLICK,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    { ...logObj }
  );
};

export const logMyLane_loadClicked = (analyticsObj: any) => {
  logFirebaseEvent(
    FirebaseEvents.CLICK,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      ...analyticsObj,
      description: 'View Load Details Within Lane',
    }
  );
};

export const logMyLane_BookItNowBtnClicked = (analyticsObj: any) => {
  logFirebaseEvent(
    FirebaseEvents.CLICK,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      ...analyticsObj,
      description: 'Quick BIN My Lanes Load',
    }
  );
};

export const logMyLane_BookItNowBtnClicked_LoadDetail = (analyticsObj: any) => {
  logFirebaseEvent(
    FirebaseEvents.CLICK,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      ...analyticsObj,
      description: 'BIN My Lanes Load',
    }
  );
};

export const logMyLane_BookItNowBtn_Canceled = (analyticsObj: any) => {
  logFirebaseEvent(
    FirebaseEvents.CLICK,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      ...analyticsObj,
      description: 'Cancel BIN',
    }
  );
};
export const logMyLane_BookItNowBtn_Confirm = (analyticsObj: any) => {
  logFirebaseEvent(
    FirebaseEvents.CLICK,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      ...analyticsObj,
      description: 'Confirm BIN',
    }
  );
};

export const logMyLane_QuoteSubmitArrowBtnClicked = (
  analyticsObj: any,
  theDescription: string
) => {
  logFirebaseEvent(
    FirebaseEvents.QUOTE_SUBMISSION,
    FirebaseModules.FREIGHT,
    FirebasePages.MY_LANES,
    {
      ...analyticsObj,
      description: theDescription,
    }
  );
};
