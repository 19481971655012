import React, { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/browser';

interface State {
  eventId: null | string;
  hasError: boolean;
}

class ErrorBoundary extends React.Component<PropsWithChildren<{}>, State> {
  state = { eventId: null, hasError: false };

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: { [key: string]: any }) {
    try {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    } catch (err) {
      console.log('error not caught by Sentry');
    }
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    // TODO future we can do something different other than just rendering the children
    return this.props.children;
  }
}

export default ErrorBoundary;
