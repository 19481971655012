import { getUserEmail } from './authUtils';

const FEATURE_SERVICE_URL = process.env.FEATURE_SERVICE_URL || '';

export const getFeature = async (feature: string) => {
  const isActive = await fetch(
    `${FEATURE_SERVICE_URL}/${feature}/${getUserEmail()}`
  )
    .then((res) => res.json())
    .then((data) => {
      return data.status === 'always-active';
    });
  return isActive;
};
